import { ConversationPath } from 'common/channels'
import { Bubble, Conversation, ConversationId } from 'common/types'
import React, { useCallback, useContext } from 'react'
import { increment } from 'shared/firebase/serverValue'
import { refFullPath } from 'shared/firebase/typedMethods'
import { FacilityId } from 'shared/types/utils'
import { MessageInputForm } from '../../components/conversation/MessageInputForm'
import { useUser } from '../../components/UserProvider'
import { push, set, update } from '../../firebaseMethods'
import { ConversationContext } from './Communication'
import { contactsContext } from './contactsProvider'
import { saveFacilityChannelBubble, updateBubble } from './GroupComposer'

interface Props {
  conversationId: ConversationId | undefined
  conversationContext: ConversationContext
  facilityId: FacilityId
}

export const Composer: React.FC<Props> = ({
  conversationId,
  conversationContext,
  facilityId,
}) => {
  const { uid, role } = useUser()
  const { contacts } = useContext(contactsContext)

  const isAdmin = role === 'admin'

  const saveBubble = useCallback(
    async (bubble: Bubble): Promise<ConversationPath[]> => {
      const isChannel = conversationContext.channelId !== undefined

      if (isChannel) {
        return Promise.all([
          saveFacilityChannelBubble(
            conversationContext.channelId,
            contacts,
            facilityId,
            bubble,
          ),
        ])
      } else {
        let pushRef
        // Regular 1-1 conversation
        if (conversationId) {
          await Promise.all([
            update(
              `discussions/${facilityId}/${conversationContext.userId}/${uid}`,
              {
                unreadCount: increment(),
              },
            ),
          ])
          pushRef = await push(
            `conversations/${facilityId}/${conversationId}`,
            bubble,
          )
        } else {
          // Create a new conversation
          const conversationId: ConversationId = push(
            `conversations/${facilityId}`,
            {} as Conversation,
          ).key
          await Promise.all([
            set(
              `discussions/${facilityId}/${uid}/${conversationContext.userId}`,
              {
                conversationId,
                unreadCount: 0,
              },
            ),
            set(
              `discussions/${facilityId}/${conversationContext.userId}/${uid}`,
              {
                conversationId,
                unreadCount: 1,
              },
            ),
          ])

          pushRef = await push(
            `conversations/${facilityId}/${conversationId}`,
            bubble,
          )
        }

        return [refFullPath(pushRef)] as ConversationPath[]
      }
    },
    [contacts, conversationId, conversationContext, facilityId, uid],
  )

  return (
    <MessageInputForm
      saveBubble={saveBubble}
      isFileUploadEnabled={isAdmin}
      updateBubble={updateBubble}
    />
  )
}
