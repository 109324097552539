import { Trans } from '@lingui/macro'
import { ChannelId } from 'common/types'
import React, { useContext, useState } from 'react'
import { Centered } from 'shared/components/Centered'
import { FacilityId, UserId } from 'shared/types/utils'
import { Deferred } from 'shared/utils/web/deferred'
import { USER_CLOSED_DIALOG } from '../../components/Dialog'
import { Contacts } from './Contacts'
import { Conversation } from './Conversation'
import { contactsContext } from './contactsProvider'

export type ConversationContext =
  | { userId: UserId; channelId: undefined }
  | { channelId: ChannelId; channelName: string }

export const Communication: React.FC<{
  deferred: Deferred<void>
  facilityId: FacilityId
}> = ({ deferred, facilityId }) => {
  const { loading, error, discussions } = useContext(contactsContext)

  // undefined when no conversation is displayed
  const [conversationContext, setConversationContext] =
    useState<ConversationContext>()

  return (
    <div className="bg-oso-background text-menu absolute inset-0 flex min-h-screen flex-col space-y-3 p-1">
      {loading && (
        <Centered>
          <Trans>Chargement...</Trans>
        </Centered>
      )}
      {error && (
        <Centered>
          <Trans>Erreur</Trans>
        </Centered>
      )}
      {conversationContext ? (
        <Conversation
          conversationContext={conversationContext}
          facilityId={facilityId}
          conversationId={
            conversationContext.channelId === undefined
              ? discussions?.[conversationContext.userId]?.conversationId
              : conversationContext.channelId
          }
          onClose={() => setConversationContext(undefined)}
        />
      ) : (
        <Contacts
          setConversationContext={setConversationContext}
          onClose={() => deferred.reject(USER_CLOSED_DIALOG)}
        />
      )}
    </div>
  )
}
