import { t, Trans } from '@lingui/macro'
import {
  ADMIN_USER_ID,
  ConversationPath,
  UPLOAD_IN_PROGRESS,
} from 'common/channels'
import { Bubble, BubbleUpdate } from 'common/types'
import { Send, Trash } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { serverTimestamp } from 'shared/firebase/serverValue'
import { AudioTextInput } from '../AudioTextInput'
import { FileInput } from '../FileInput'
import { Button } from '../ui/Button'
import { useUser } from '../UserProvider'
import { uploadImage, uploadSound } from './fileUploader'

interface Props {
  saveBubble: (bubble: Bubble) => Promise<ConversationPath[]>
  isFileUploadEnabled: boolean
  updateBubble: (
    bubblePaths: ConversationPath[],
    updatedBubble: BubbleUpdate,
  ) => Promise<void>
}

export const MessageInputForm: React.FC<Props> = ({
  saveBubble,
  isFileUploadEnabled,
  updateBubble,
}) => {
  const { uid, role } = useUser()

  const [text, setText] = useState('')
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null)
  const [photo, setPhoto] = useState<File | null>(null)
  const [error, setError] = useState<string | undefined>()

  useEffect(() => {
    if (text || photo || audioBlob) {
      resetError()
    }
  }, [text, photo, audioBlob])

  const resetError = () => setError(undefined)

  async function sendMessage(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    resetError()

    const bubbleBase = {
      timestamp: serverTimestamp(),
      userId: role === 'admin' ? ADMIN_USER_ID : uid,
      ...(photo ? { image: { url: UPLOAD_IN_PROGRESS, path: '' } } : {}),
    }
    let bubble: Bubble

    if (audioBlob) {
      bubble = {
        ...bubbleBase,
        sound: { url: UPLOAD_IN_PROGRESS, path: '' },
      }
    } else if (text) {
      bubble = {
        ...bubbleBase,
        text,
      }
    } else return

    const bubblePaths = await saveBubble(bubble)

    setText('')
    clearAudio()
    clearPhoto()

    try {
      let updatedBubble: BubbleUpdate = {}
      if (photo) {
        const [path, url] = await uploadImage(photo)
        if (!url) {
          setError(t`Erreur lors de l'envoi de l'image`)
          return
        }
        updatedBubble = { ...updatedBubble, image: { url, path } }
      }

      if (audioBlob) {
        const [path, url] = await uploadSound(audioBlob)
        if (!path) {
          setError(t`Erreur lors de l'envoi de l'audio`)
          return
        }
        updatedBubble = { ...updatedBubble, sound: { url, path } }
      }

      await updateBubble(bubblePaths, updatedBubble)
    } catch (err) {
      setError(t`Erreur lors de l'envoi du message`)
    }
  }

  function clearAudio() {
    setAudioBlob(null)
    resetError()
  }

  function clearPhoto() {
    setPhoto(null)
    resetError()
  }

  return (
    <div className="relative">
      <form
        method="post"
        onSubmit={sendMessage}
        className="flex flex-col rounded-lg p-2"
      >
        <div className="flex items-end space-x-2">
          {isFileUploadEnabled && (
            <FileInput onFileSelected={setPhoto} file={photo} />
          )}
          <AudioTextInput
            text={text}
            onTextChanged={setText}
            audioBlob={audioBlob}
            onAudioRecorded={setAudioBlob}
            placeholder={t`Votre message ici ...`}
            variant="textarea"
          />
          <Button
            type="submit"
            variant="submit"
            size="icon"
            disabled={(!text && !audioBlob) || !!error}
            className="sm:w-auto sm:px-4 sm:py-2"
          >
            <Send className="size-4 sm:mr-2" />
            <span className="hidden sm:block">
              <Trans>Envoyer</Trans>
            </span>
          </Button>
        </div>
        {error && <div className="text-destructive mt-1">{error}</div>}
      </form>
      {photo && (
        <div className="absolute bottom-full left-0">
          <div className="relative inline-block rounded-lg p-2 shadow-lg">
            <img
              src={URL.createObjectURL(photo)}
              alt={t`Photo téléversée`}
              className="max-h-64 rounded object-cover"
            />
            <Button
              variant="destructive"
              size="icon"
              className="absolute right-3 top-3"
              onClick={clearPhoto}
            >
              <Trash className="size-4" />
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
