import { X } from 'lucide-react'
import React, { useState } from 'react'
import { cn } from 'shared/utils/web/cn'
import { Button } from './ui/Button'

interface ExpandableImageProps {
  src: string
  alt?: string
  className?: string
}

const ExpandableImage: React.FC<ExpandableImageProps> = ({
  src,
  alt,
  className,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const handleExpand = () => setIsExpanded(true)
  const handleClose = () => setIsExpanded(false)

  return (
    <>
      <img
        src={src}
        alt={alt}
        className={cn(className, 'cursor-pointer')}
        loading="lazy"
        onClick={handleExpand}
      />
      {isExpanded && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black/70"
          onClick={handleClose}
        >
          <Button
            variant="destructive"
            size="icon"
            className="absolute right-2 top-2"
            onClick={handleClose}
          >
            <X className="size-8" />
          </Button>
          <div className="relative">
            <img
              src={src}
              alt={alt}
              className="max-h-[90vh] max-w-[90vw] object-contain"
            />
          </div>
        </div>
      )}
    </>
  )
}

export default ExpandableImage
