import { Dialog as GenericDialog } from 'shared/components/Dialog'

export { USER_CLOSED_DIALOG } from 'shared/components/Dialog'

export const USER_BACK_BUTTON = 'USER_BACK_BUTTON'

export const Dialog: React.FC<
  React.PropsWithChildren<{ onClose: () => void }>
> = ({ children, onClose }) => (
  <GenericDialog onClose={onClose}>
    <div className="bg-background-dialog text-dialog flex max-h-full flex-col space-y-3 p-3 text-xl">
      {children}
    </div>
  </GenericDialog>
)
