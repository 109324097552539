import { ButtonHTMLAttributes } from 'react'
import { cn } from 'shared/utils/web/cn'

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>

const Button: React.FC<ButtonProps> = ({ children, className, ...props }) => (
  <button
    className={cn(
      'cursor-pointer px-2 py-1 text-center focus:outline-none disabled:cursor-not-allowed disabled:opacity-50',
      className,
    )}
    {...props}
  >
    {children}
  </button>
)

export const GrayButton: React.FC<ButtonProps> = ({
  children,
  className,
  ...props
}) => (
  <Button className={cn('bg-background-button rounded', className)} {...props}>
    {children}
  </Button>
)

export const YellowButton: React.FC<ButtonProps> = ({
  children,
  className,
  ...props
}) => (
  <Button
    className={cn('border-background-alert border-2', className)}
    {...props}
  >
    {children}
  </Button>
)

export const DialogButton: React.FC<ButtonProps> = ({ children, ...props }) => (
  <button
    className="flex-1 cursor-pointer self-center break-words border-2 border-current bg-transparent px-3 py-1 text-center focus:outline-none disabled:cursor-not-allowed disabled:opacity-50"
    {...props}
  >
    {children}
  </button>
)
