import { osoChannelId } from 'common/channels'
import { DatabaseSchema } from 'common/databaseSchema'
import { CheckCheck } from 'lucide-react'
import { useCallback, useContext, useMemo, useState } from 'react'
import { Centered } from 'shared/components/Centered'
import { MergedType } from 'shared/hooks/createUseMergedFirebase'
import { FacilityId } from 'shared/types/utils'
import { cn } from 'shared/utils/web/cn'
import { Button } from '../../components/ui/Button'
import { facilitiesContext } from '../../contexts/FacilitiesProvider'
import { useLastReadTS } from '../../hooks/useLastRead'
import { useMergedFirebase } from '../../hooks/useMergedFirebase'
import { Conversation, LastReadTS } from '../Live/Conversation'
import { GroupComposer } from '../Live/GroupComposer'
import { contactsContext, ContactsProvider } from '../Live/contactsProvider'
import { UnreadCount } from './UnreadCount'

export type FacilityLastReadTS = Record<FacilityId, LastReadTS>

export const Messaging = () => {
  const { facilities } = useContext(facilitiesContext)

  const [currentFacilityId, setCurrentFacilityId] = useState<FacilityId>()

  const [isSelectionEnable, setSelectionEnable] = useState(false)
  const [selectedFacilityIds, setSelectedFacilityIds] = useState<
    Set<FacilityId>
  >(new Set())
  const { lastReadTS, updateLastRead } = useLastReadTS(facilities)

  // Load all channel conversations
  const refPathsMap = useMemo(
    () =>
      Object.keys(facilities).reduce<Record<FacilityId, string>>(
        (acc, facilityId) => {
          acc[facilityId] = `conversations/${facilityId}/${osoChannelId}`
          return acc
        },
        {},
      ),
    [facilities],
  )

  const {
    data: facilityConversations,
    loading,
    error,
  } = useMergedFirebase<
    MergedType<'conversations/${string}/${string}', DatabaseSchema>
  >(refPathsMap)

  const toggleGroupFacilityId = useCallback(
    (facilityId: FacilityId) => {
      if (selectedFacilityIds.has(facilityId)) {
        selectedFacilityIds.delete(facilityId)
      } else selectedFacilityIds.add(facilityId)
      setSelectedFacilityIds(new Set(selectedFacilityIds))
    },
    [selectedFacilityIds],
  )

  const selectAllFacilityIds = useCallback(
    () => setSelectedFacilityIds(new Set(Object.keys(facilities))),
    [facilities],
  )

  const resetGroupFacilityIds = () => setSelectedFacilityIds(new Set())

  const markConversationsAsRead = useCallback(() => {
    updateLastRead([...selectedFacilityIds.keys()], Date.now())
  }, [selectedFacilityIds, updateLastRead])

  const updateLastReadForFacility = (
    facilityId: FacilityId,
    timestamp: number,
  ) => {
    updateLastRead([facilityId], timestamp)
  }

  if (loading) return <Centered>Chargement...</Centered>
  if (error) return <Centered>Erreur</Centered>

  return (
    <div className="flex max-h-[calc(100vh-40px)] flex-row">
      <div className="flex max-h-full flex-col overflow-y-auto">
        <div className="sticky top-0 flex flex-col gap-2 bg-gray-700 p-2 shadow-xl">
          <div className="flex items-center justify-between">
            <input
              type="checkbox"
              id="group"
              checked={isSelectionEnable}
              onChange={() => {
                setSelectionEnable(!isSelectionEnable)
                resetGroupFacilityIds()
              }}
              className="size-4"
            />
            <label
              htmlFor="group"
              className="max-w-80 grow cursor-pointer pl-4"
            >
              Sélection ({selectedFacilityIds.size})
            </label>
            <Button
              disabled={!isSelectionEnable}
              onClick={() =>
                selectedFacilityIds.size > 0
                  ? resetGroupFacilityIds()
                  : selectAllFacilityIds()
              }
              className="bg-background text-primary hover:bg-background/60 shadow"
            >
              {selectedFacilityIds.size > 0 ? 'Aucun' : 'Tous'}
            </Button>
          </div>
          <Button
            onClick={markConversationsAsRead}
            disabled={!isSelectionEnable || !selectedFacilityIds.size}
            className="bg-background text-primary hover:bg-background/60 gap-2 shadow"
          >
            Marquez comme lu la sélection
            <CheckCheck />
          </Button>
        </div>
        {Object.entries(facilities).map(([facilityId, facility]) => (
          <div
            key={facilityId}
            className={cn(
              'flex cursor-pointer items-center px-2 hover:bg-gray-600',
              facilityId === currentFacilityId && !isSelectionEnable
                ? 'bg-gray-500'
                : '',
            )}
            onClick={() =>
              !isSelectionEnable && setCurrentFacilityId(facilityId)
            }
          >
            {isSelectionEnable ? (
              <input
                type="checkbox"
                id={`group-${facilityId}`}
                checked={selectedFacilityIds.has(facilityId)}
                onChange={() => toggleGroupFacilityId(facilityId)}
                className="size-4"
              />
            ) : (
              <div className="size-4" />
            )}
            <label
              className="h-full max-w-80 grow cursor-pointer truncate py-0.5 pl-4"
              htmlFor={`group-${facilityId}`}
            >
              {facility.name}
            </label>
            <UnreadCount
              conversation={facilityConversations[facilityId] ?? {}}
              lastReadTS={lastReadTS[facilityId]}
            />
          </div>
        ))}
      </div>
      <div className="flex flex-1 justify-center">
        <div className="flex flex-1 flex-col gap-4 p-2 text-white">
          {isSelectionEnable ? (
            <div className="flex flex-col gap-4">
              <div className="flex-1 text-center text-2xl font-bold">
                Message groupé
              </div>
              {selectedFacilityIds.size >= 2 ? (
                <>
                  <div>
                    à destination de {selectedFacilityIds.size} établissements
                  </div>
                  <GroupComposer
                    channelId={osoChannelId}
                    facilityIds={selectedFacilityIds}
                  />
                </>
              ) : (
                <div>Sélectionnez plusieurs établissements</div>
              )}
            </div>
          ) : currentFacilityId ? (
            <ContactsProvider facilityId={currentFacilityId}>
              <ConversationLoader
                facilityId={currentFacilityId}
                facilityName={facilities[currentFacilityId].name}
                lastReadTS={lastReadTS[currentFacilityId] ?? 0}
                onUpdateLastRead={updateLastReadForFacility}
              />
            </ContactsProvider>
          ) : (
            <div>Sélectionnez un établissement</div>
          )}
        </div>
      </div>
    </div>
  )
}

interface ConversationLoaderProps {
  facilityId: FacilityId
  facilityName: string
  lastReadTS: LastReadTS
  onUpdateLastRead: (facilityId: FacilityId, timestamp: number) => void
}

const ConversationLoader: React.FC<ConversationLoaderProps> = ({
  facilityId,
  facilityName,
  lastReadTS,
  onUpdateLastRead,
}) => {
  const { loading, error } = useContext(contactsContext)

  const updateLastReadTS = useCallback(
    (ts: number) => onUpdateLastRead(facilityId, ts),
    [facilityId, onUpdateLastRead],
  )

  if (loading) return <Centered>Chargement...</Centered>
  if (error) return <Centered>Erreur</Centered>

  return (
    <Conversation
      facilityId={facilityId}
      conversationId={osoChannelId}
      conversationContext={{
        channelId: osoChannelId,
        channelName: facilityName,
      }}
      lastReadTS={lastReadTS}
      updateLastReadTS={updateLastReadTS}
    />
  )
}
