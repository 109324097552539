import { SelectHTMLAttributes } from 'react'
import { cn } from 'shared/utils/web/cn'

type SelectProps = SelectHTMLAttributes<HTMLSelectElement>

export const Select: React.FC<SelectProps> = ({
  children,
  className,
  ...props
}) => (
  <select
    className={cn(
      'cursor-pointer rounded px-2 py-1 text-center focus:outline-none disabled:opacity-50',
      className,
    )}
    {...props}
  >
    {children}
  </select>
)
