import { ADMIN_USER_ID, UPLOAD_IN_PROGRESS } from 'common/channels'
import { Bubble as BubbleType, isAudioBubble, isTextBubble } from 'common/types'
import { Image, LoaderCircle, Play } from 'lucide-react'
import { DateTime } from 'luxon'
import React from 'react'
import { formatTime } from 'shared/utils/time'
import { cn } from 'shared/utils/web/cn'
import { AudioPlayer } from '../AudioPlayer'
import ExpandableImage from '../ExpandableImage'
import { Button } from '../ui/Button'
import { useUser } from '../UserProvider'

interface Props {
  bubble: BubbleType
  author: string | undefined
  zone: string | undefined
}

export const Bubble: React.FC<Props> = ({ bubble, author, zone }) => {
  const { uid, role } = useUser()

  const isUserBubble =
    bubble.userId === uid ||
    (bubble.userId === ADMIN_USER_ID && role === 'admin')

  const isUploadingAudioBubble =
    isAudioBubble(bubble) && bubble.sound.url === UPLOAD_IN_PROGRESS

  const isUploadingImageBubble = bubble.image?.url === UPLOAD_IN_PROGRESS

  return (
    <div
      className={cn(
        'flex flex-col',
        isUserBubble ? 'items-end' : 'items-start',
      )}
    >
      <div
        className={cn(
          'mb-1 flex items-center gap-2',
          isUserBubble ? 'justify-end' : 'justify-start',
        )}
      >
        {author && (
          <span className="text-sm font-bold">
            {zone && <>{zone} - </>}
            {author}
          </span>
        )}
        <time className="text-sm">{formatDate(bubble.timestamp)}</time>
      </div>
      <div
        className={cn(
          'rounded-lg p-3 shadow-sm',
          isUserBubble
            ? 'rounded-tr-none bg-white text-black'
            : 'bg-oso-secondary rounded-tl-none text-white',
          isAudioBubble(bubble) ? 'w-[80vw] max-w-96' : 'w-fit max-w-[80vw]',
        )}
      >
        {isTextBubble(bubble) && (
          <p className="whitespace-pre-wrap text-pretty">{bubble.text}</p>
        )}

        {isAudioBubble(bubble) &&
          (isUploadingAudioBubble ? (
            <div className="bg-secondary flex h-10 items-center justify-between rounded-md shadow-sm">
              <Button size="icon" variant="secondary" type="button" disabled>
                <Play className="size-4" />
              </Button>
              <div className="flex grow justify-center">
                <LoaderCircle className="text-secondary-foreground size-6 animate-spin" />
              </div>
            </div>
          ) : (
            <AudioPlayer url={bubble.sound.url} />
          ))}
        {bubble.image &&
          (isUploadingImageBubble ? (
            <div className="relative mt-2 flex size-24 items-center justify-center border">
              <Image className="absolute inset-0 h-full w-full object-cover text-gray-400" />
              <LoaderCircle className="relative size-8 animate-spin" />
            </div>
          ) : (
            <ExpandableImage
              src={bubble.image.url}
              alt="Bubble Image"
              className="mt-2 h-auto max-h-64 w-auto max-w-full object-contain"
            />
          ))}
      </div>
    </div>
  )
}

function formatDate(timestamp: number) {
  const date = DateTime.fromMillis(timestamp)
  const today = DateTime.now()

  if (date.hasSame(today, 'day')) {
    return formatTime(date)
  }

  return date.toLocaleString({
    weekday: 'long',
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h23',
  })
}
