import { plural } from '@lingui/macro'
import { DatabaseSchema } from 'common/databaseSchema'
import { SnoozeHistory } from 'common/types'
import React, { useContext, useMemo, useState } from 'react'
import { MergedType } from 'shared/hooks/createUseMergedFirebase'
import { AlertType, SerialAlerts, alertTypeString } from 'shared/types/alert'
import { TimeRange } from 'shared/types/timeRange'
import { isObjectEmpty } from 'shared/utils/defined'
import { dateTimeFromISO, ms } from 'shared/utils/time'
import { isWithinTimeRange } from 'shared/utils/timeRange'
import { Select } from '../../components/Select'
import { facilitiesContext } from '../../contexts/FacilitiesProvider'
import { useFirebase } from '../../hooks/useFirebase'
import { useMergedFirebase } from '../../hooks/useMergedFirebase'
import { Event as EventComponent } from './Event'

export interface FirebaseAlertWithKey extends Alert {
  key: string
}

export interface Event {
  serial: string
  date: string
  device: FacilityDevice
  facilityName: string
  alerts: FirebaseAlertWithKey[]
  snoozeHistory: SnoozeHistory
  priorityAlertIndex: number
}

function findLastIndex<T>(array: T[], predicate: (value: T) => boolean) {
  const index = [...array].reverse().findIndex(predicate)
  if (index === -1 /* Not found */) return -1
  return array.length - 1 - index
}

function isValidatedAlert(monitoringTimeRange: TimeRange) {
  return (alert: FirebaseAlertWithKey) => {
    // Look for validated alerts...
    if (alert.validation?.isValidated !== true) return false

    const dateTime = dateTimeFromISO(alert.date)

    // ... that are not outside of monitoring time range
    return isWithinTimeRange(dateTime, monitoringTimeRange)
  }
}

const ALL_ALERTS = 'ALL'

export const DevicesAlerts: React.FC<{
  date: string
}> = ({ date }) => {
  const { facility } = useContext(facilitiesContext)
  const facilityName = facility.name

  const [onlyShowValidated, setOnlyShowValidated] = useState(false)
  const [alertType, setAlertType] = useState<AlertType | typeof ALL_ALERTS>(
    ALL_ALERTS,
  )

  const { data: allAlerts, loading, error } = useFirebase(`alerts/${date}`)

  const facilityAlerts = useMemo(() => {
    return Object.entries(allAlerts ?? {}).reduce<SerialAlerts>(
      (acc, [serial, alerts]) => {
        // Check if serial is part of selected facility
        // Note that devices may be undefined if there are none
        if (facility.devices?.[serial] !== undefined) acc[serial] = alerts
        return acc
      },
      {},
    )
  }, [allAlerts, facility.devices])

  const alerts = useMemo(() => {
    return Object.entries(facilityAlerts).reduce<SerialAlerts>(
      (acc, [serial, alerts]) => {
        const typeAlerts = Object.entries(alerts).reduce<Record<string, Alert>>(
          (acc, [alertId, alert]) => {
            if (alertType === ALL_ALERTS || alert.type === alertType)
              acc[alertId] = alert
            return acc
          },
          {},
        )
        if (!isObjectEmpty(typeAlerts)) acc[serial] = typeAlerts
        return acc
      },
      {},
    )
  }, [facilityAlerts, alertType])

  type BareEvent = Omit<Event, 'snoozeHistory' | 'priorityAlertIndex'>

  const events = useMemo(
    () =>
      Object.entries(alerts).reduce<BareEvent[]>((acc, [serial, alertsMap]) => {
        if (facility.devices === undefined)
          throw 'Impossible based on above test'
        const device = facility.devices[serial]

        // Most recent alert comes first
        const serialAlerts: FirebaseAlertWithKey[] = Object.entries(alertsMap)
          .map(([key, alert]) => ({ ...alert, key }))
          .sort(({ date: dateA }, { date: dateB }) =>
            dateB.localeCompare(dateA),
          )

        function addEvent() {
          acc.push({ serial, date, device, facilityName, alerts })
        }

        // Group alerts into events when no large time gap between them
        const GAP_THRESHOLD = ms(30, 'minutes')
        let alerts: FirebaseAlertWithKey[] = []
        serialAlerts.forEach((alert) => {
          if (alerts.length === 0) {
            alerts.push(alert)
          } else {
            const lastAlert = alerts[alerts.length - 1]
            const deltaTime =
              dateTimeFromISO(lastAlert.date).valueOf() -
              dateTimeFromISO(alert.date).valueOf()
            if (deltaTime < GAP_THRESHOLD) {
              alerts.push(alert)
            } else {
              addEvent()
              alerts = [alert]
            }
          }
        })

        addEvent()

        return acc
      }, []),
    [date, facility.devices, alerts, facilityName],
  )

  const snoozePathsMap = useMemo(
    () =>
      Object.keys(facilityAlerts).reduce<Record<Serial, string>>(
        (acc, serial) => {
          acc[serial] = `snooze/${date}/${serial}`
          return acc
        },
        {},
      ),
    [facilityAlerts, date],
  )

  const { data: serialSnoozeHistory, error: snoozeError } =
    useMergedFirebase<MergedType<'snooze/${string}/${string}', DatabaseSchema>>(
      snoozePathsMap,
    )

  const sortedEvents = useMemo(
    () =>
      events
        .map((event) => {
          const snoozeHistory = (serialSnoozeHistory ?? {})[event.serial] ?? {}
          const monitoringTimeRange = facility.monitoringTimeRange
          // find last since alerts are sorted anti-chronologically
          const priorityAlertIndex = findLastIndex(
            event.alerts,
            isValidatedAlert(monitoringTimeRange),
          )

          return { ...event, snoozeHistory, priorityAlertIndex }
        })
        .filter((event) => !onlyShowValidated || event.priorityAlertIndex >= 0)
        .sort(
          (
            { alerts: alertsA, priorityAlertIndex: priorityIndexA },
            { alerts: alertsB, priorityAlertIndex: priorityIndexB },
          ) => {
            const indexA = priorityIndexA >= 0 ? priorityIndexA : 0
            const indexB = priorityIndexB >= 0 ? priorityIndexB : 0

            return alertsB[indexB].date.localeCompare(alertsA[indexA].date)
          },
        ),
    [
      events,
      facility.monitoringTimeRange,
      serialSnoozeHistory,
      onlyShowValidated,
    ],
  )

  if (error || snoozeError) return <span className="mx-auto">Erreur</span>
  if (loading) return <span className="mx-auto">Chargement...</span>

  return (
    <div className="flex flex-col gap-6">
      <div className="mx-auto">
        <div className="flex flex-row flex-wrap items-baseline gap-6">
          <label>
            <input
              className="mr-2"
              type="checkbox"
              checked={onlyShowValidated}
              onChange={() => setOnlyShowValidated(!onlyShowValidated)}
            />
            Validées
          </label>
          <Select
            className="rounded-none border-2 border-current bg-transparent"
            onChange={(event) => setAlertType(event.target.value as AlertType)}
            value={alertType}
          >
            <option value={ALL_ALERTS}>Toutes</option>
            {Object.entries(alertTypeString)
              .sort(([_type1, text1], [_type2, text2]) =>
                text1.localeCompare(text2),
              )
              .map(([type, text]) => (
                <option value={type} key={type}>
                  {text}
                </option>
              ))}
          </Select>
          {plural(sortedEvents.length, {
            one: '# évènement',
            other: '# évènements',
          })}
        </div>
      </div>
      {sortedEvents.map((event) => {
        return (
          <EventComponent
            key={event.alerts[event.alerts.length - 1].key}
            {...event}
          />
        )
      })}
    </div>
  )
}
