import poster1 from './poster-1.jpg'
// import poster2 from './poster-2.jpg'
import poster3 from './poster-3.jpg'
import poster4 from './poster-4.jpg'
import poster5 from './poster-5.jpg'
// import poster6 from './poster-6.jpg'
// import poster7 from './poster-7.jpg'

import tutorial1 from './Tuto video 1 - quesaco.mp4'
// import tutorial2 from './Tuto video 2 - gerer une alerte.mp4'
import tutorial3 from './Tuto video 3 - signification icones.mp4'
import tutorial4 from './Tuto video 4 - chambre hors ligne.mp4'
import tutorial5 from './Tuto video 5 - confidentialite.mp4'
// import tutorial6 from './Tuto video 6 - communication collegue.mp4'
// import tutorial7 from './Tuto video 7 - communication oso.mp4'

type TutorialVideos = { src: string; poster: string }

// commented tutosos are obsoletes
export const TUTORIAL_VIDEOS: TutorialVideos[] = [
  { src: tutorial1, poster: poster1 },
  // { src: tutorial2, poster: poster2 },
  { src: tutorial3, poster: poster3 },
  { src: tutorial4, poster: poster4 },
  { src: tutorial5, poster: poster5 },
  // { src: tutorial6, poster: poster6 },
  // { src: tutorial7, poster: poster7 },
]
