import { Trans } from '@lingui/macro'
import React, { useContext, useEffect, useState } from 'react'
import { FacilityId } from 'shared/types/utils'
import { isObjectEmpty } from 'shared/utils/defined'
import { Deferred } from 'shared/utils/web/deferred'
import { DialogButton } from '../../components/Button'
import {
  Dialog,
  USER_BACK_BUTTON,
  USER_CLOSED_DIALOG,
} from '../../components/Dialog'
import { ListItem } from '../../components/ListItem'
import { useUser } from '../../components/UserProvider'
import { useFirebase } from '../../hooks/useFirebase'
import MenuIcon from '../../icons/menu.svg?react'
import PhoneIcon from '../../icons/phone.svg?react'
import { isPhoneCallEnabled } from './android'
import { Communication } from './Communication'
import { ConfirmEmergencyCallDialog } from './ConfirmEmergencyCallDialog'
import { contactsContext } from './contactsProvider'
import { EmergencyCallContext } from './EmergencyCallContext'
import { FAQDialog } from './FAQDialog'
import { HelpDialog } from './HelpDialog'
import { InfoCommunicationDialog } from './InfoCommunicationDialog'
import { DeviceWithRoomExit, RoomExitsDialog } from './RoomExitsDialog'
import { SettingsDialog } from './SettingsDialog'
import { ZoneSelectionDialog } from './ZoneSelectionDialog'

export const Menu: React.FC<{ facilityId: FacilityId }> = ({ facilityId }) => {
  const {
    startEmergencyCall,
    isCallInProgress,
    isCallAnswered,
    amICalling,
    amICalled,
  } = useContext(EmergencyCallContext)

  const user = useUser()

  const { discussions } = useContext(contactsContext)

  const [nbUnreadMessages, setNbUnreadMessages] = useState(0)

  const [menuDeferred, setMenuDeferred] = useState<Deferred<void> | null>(null)

  const [helpDeferred, setHelpDeferred] = useState<Deferred<void> | null>(null)

  const [settingsDeferred, setSettingsDeferred] =
    useState<Deferred<void> | null>(null)

  const [zoneSelectionDeferred, setZoneSelectionDeferred] =
    useState<Deferred<void> | null>(null)

  const [faqDeferred, setFaqDeferred] = useState<Deferred<void> | null>(null)

  const [roomExitsDeferred, setRoomExitsDeferred] =
    useState<Deferred<void> | null>(null)

  const [confirmEmergencyCallDeferred, setConfirmEmergencyCallDeferred] =
    useState<Deferred<void> | null>(null)

  const [commScreenDeferred, setCommScreenDeferred] =
    useState<Deferred<void> | null>(null)

  const { data: devices } = useFirebase(`facilities/${facilityId}/devices`)

  const { data: zones } = useFirebase(`zones/${facilityId}`)

  useEffect(() => {
    const nbUnread = Object.values(discussions).reduce(
      (acc, userDiscussion) => acc + (userDiscussion?.unreadCount ?? 0),
      0,
    )
    setNbUnreadMessages(nbUnread)
  }, [discussions])

  const useLegacyCall = !isPhoneCallEnabled()

  const handleStartEmergencyCall = async () => {
    if (useLegacyCall) handleStartLegacyEmergencyCall()
    else showCommunicationScreen()
  }

  const showCommunicationScreen = async () => {
    const deferred = new Deferred<void>()
    setCommScreenDeferred(deferred)
    try {
      await deferred.promise
    } catch (error) {
      if (error !== USER_CLOSED_DIALOG) {
        throw error
      }
    } finally {
      setCommScreenDeferred(null)
    }
  }

  const handleStartLegacyEmergencyCall = async () => {
    const deferred = new Deferred<void>()
    setConfirmEmergencyCallDeferred(deferred)
    try {
      await deferred.promise
      startEmergencyCall()
    } catch (error) {
      if (error !== USER_CLOSED_DIALOG) {
        throw error
      }
    } finally {
      setConfirmEmergencyCallDeferred(null)
    }
  }

  const handleShowHelp = async () => {
    const deferred = new Deferred<void>()
    setHelpDeferred(deferred)
    if (window.Android?.onHelpDisplayed) window.Android.onHelpDisplayed(true)

    try {
      await deferred.promise
    } catch (error) {
      if (error === USER_CLOSED_DIALOG) {
        menuDeferred?.reject(USER_CLOSED_DIALOG)
      } else if (error !== USER_BACK_BUTTON) {
        throw error
      }
    } finally {
      setHelpDeferred(null)
      if (window.Android?.onHelpDisplayed) window.Android.onHelpDisplayed(false)
    }
  }

  const handleShowSettings = async () => {
    const deferred = new Deferred<void>()
    setSettingsDeferred(deferred)

    try {
      await deferred.promise
    } catch (error) {
      if (error === USER_CLOSED_DIALOG) {
        menuDeferred?.reject(USER_CLOSED_DIALOG)
      } else if (error !== USER_BACK_BUTTON) {
        throw error
      }
    } finally {
      setSettingsDeferred(null)
    }
  }

  const handleSelectZone = async () => {
    const deferred = new Deferred<void>()
    setZoneSelectionDeferred(deferred)
    try {
      await deferred.promise
    } catch (error) {
      if (error === USER_CLOSED_DIALOG) {
        menuDeferred?.reject(USER_CLOSED_DIALOG)
      } else if (error !== USER_BACK_BUTTON) {
        throw error
      }
    } finally {
      setZoneSelectionDeferred(null)
    }
  }

  const handleShowFAQ = async () => {
    const deferred = new Deferred<void>()
    setFaqDeferred(deferred)
    try {
      await deferred.promise
    } catch (error) {
      if (error === USER_CLOSED_DIALOG) {
        menuDeferred?.reject(USER_CLOSED_DIALOG)
      } else if (error !== USER_BACK_BUTTON) {
        throw error
      }
    } finally {
      setFaqDeferred(null)
    }
  }

  const handleShowRoomExits = async () => {
    const deferred = new Deferred<void>()
    setRoomExitsDeferred(deferred)
    try {
      await deferred.promise
    } catch (error) {
      if (error === USER_CLOSED_DIALOG) {
        menuDeferred?.reject(USER_CLOSED_DIALOG)
      } else if (error !== USER_BACK_BUTTON) {
        throw error
      }
    } finally {
      setRoomExitsDeferred(null)
    }
  }

  const handleOpenMenu = async () => {
    const deferred = new Deferred<void>()
    setMenuDeferred(deferred)
    try {
      await deferred.promise
    } catch (error) {
      if (error !== USER_CLOSED_DIALOG) {
        throw error
      }
    } finally {
      setMenuDeferred(null)
    }
  }

  const devicesWithRoomExitTimeRange = Object.values(devices ?? {}).reduce(
    (acc, { room, roomExitTimeRange }) => {
      if (roomExitTimeRange !== undefined) {
        acc.push({
          room,
          roomExitTimeRange,
        })
      }

      return acc
    },
    [] as DeviceWithRoomExit[],
  )

  const showRoomExits = devicesWithRoomExitTimeRange.length > 0

  const showZoneSelection =
    user.role === 'AS' && !isObjectEmpty(zones ?? {}) && !user.zoneLocked

  return (
    <>
      {menuDeferred && (
        <Dialog onClose={() => menuDeferred.reject(USER_CLOSED_DIALOG)}>
          <div className="text-center">
            <Trans>Menu</Trans>
          </div>
          <div className="flex flex-col space-y-4">
            <ListItem onClick={handleShowHelp}>
              <Trans>Aide à l'utilisation</Trans>
            </ListItem>
            <ListItem onClick={handleShowFAQ}>
              <Trans>Foire aux Questions</Trans>
            </ListItem>
            {showZoneSelection && (
              <ListItem onClick={handleSelectZone}>
                <Trans>Sélection des chambres</Trans>
              </ListItem>
            )}
            {showRoomExits && (
              <ListItem onClick={handleShowRoomExits}>
                <Trans>Détection des sorties de chambre</Trans>
              </ListItem>
            )}
            <InfoCommunicationDialog />

            <ListItem onClick={handleShowSettings}>
              <Trans>Réglages</Trans>
            </ListItem>
          </div>
          <div className="flex flex-row">
            <DialogButton onClick={() => menuDeferred.resolve()}>
              <Trans>Fermer</Trans>
            </DialogButton>
          </div>
        </Dialog>
      )}
      {helpDeferred && (
        <HelpDialog
          deferred={helpDeferred}
          showZoneSelection={showZoneSelection}
        />
      )}
      {settingsDeferred && <SettingsDialog deferred={settingsDeferred} />}
      {commScreenDeferred && (
        <Communication deferred={commScreenDeferred} facilityId={facilityId} />
      )}
      {zoneSelectionDeferred && (
        <ZoneSelectionDialog
          deferred={zoneSelectionDeferred}
          zones={zones ?? {}}
        />
      )}
      {roomExitsDeferred && (
        <RoomExitsDialog
          deferred={roomExitsDeferred}
          devicesWithRoomExit={devicesWithRoomExitTimeRange}
        />
      )}
      {faqDeferred && <FAQDialog deferred={faqDeferred} />}
      {confirmEmergencyCallDeferred && (
        <ConfirmEmergencyCallDialog deferred={confirmEmergencyCallDeferred} />
      )}

      <div className="shadow-menu-shadow flex flex-row text-base shadow">
        {!amICalling && !amICalled && (
          <>
            <button
              className="bg-background text-menu flex flex-1 cursor-pointer flex-row items-center justify-center space-x-3 border border-current py-2 focus:outline-none disabled:opacity-50"
              onClick={handleOpenMenu}
            >
              <MenuIcon fill="currentColor" className="w-6" />
              <div>
                <Trans>Menu</Trans>
              </div>
            </button>
            <button
              className="bg-background-phone text-menu flex flex-1 cursor-pointer flex-row items-center justify-center space-x-3 border border-current border-l-transparent py-2 focus:outline-none disabled:opacity-50"
              onClick={handleStartEmergencyCall}
              disabled={isCallInProgress}
            >
              <PhoneIcon fill="currentColor" className="w-6" />
              <div>
                {isCallInProgress && isCallAnswered ? (
                  <Trans>Un appel d'urgence est déjà en cours...</Trans>
                ) : useLegacyCall ? (
                  <Trans>Appel collègue</Trans>
                ) : (
                  <Trans>Répertoire</Trans>
                )}
              </div>
              {nbUnreadMessages > 0 && (
                <div className="bg-background-notification h-4 w-4 rounded-full" />
              )}
            </button>
          </>
        )}
      </div>
    </>
  )
}
