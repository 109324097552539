import { InputHTMLAttributes } from 'react'
import { cn } from 'shared/utils/web/cn'

type InputProps = InputHTMLAttributes<HTMLInputElement>

export const Input = ({ children, ...props }: InputProps) => (
  <input
    className="bg-background-button placeholder-primary cursor-pointer rounded px-2 py-1 placeholder-opacity-30 focus:outline-none disabled:opacity-50"
    {...props}
  >
    {children}
  </input>
)

export const DialogInput = ({ children, className, ...props }: InputProps) => (
  <input
    className={cn(
      'text-dialog placeholder-primary border-2 border-current bg-transparent px-2 py-1 placeholder-opacity-30 focus:outline-none disabled:opacity-50',
      className,
    )}
    {...props}
  >
    {children}
  </input>
)
