import * as Sentry from '@sentry/react'
import { Pause, Play } from 'lucide-react'
import { memo, useCallback, useEffect, useRef, useState } from 'react'
import WaveSurfer from 'wavesurfer.js'
import { Button } from './ui/Button'

export const AudioPlayer = ({
  url,
  buttons,
}: {
  url: string
  buttons?: React.ReactNode
}) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false)
  const [showFakeElement, setShowFakeElement] = useState<boolean>(false)

  const audioElementRef = useRef<HTMLAudioElement | null>(null)

  const waveformRef = useRef<HTMLDivElement | null>(null)
  const wavesurferRef = useRef<WaveSurfer | null>(null)

  useEffect(() => {
    if (audioElementRef.current && waveformRef.current) {
      wavesurferRef.current = WaveSurfer.create({
        container: waveformRef.current,
        barWidth: 4,
        waveColor: getComputedStyle(document.documentElement).getPropertyValue(
          '--wavesurfer',
        ),
        barRadius: 10,
        height: 39, // h-10 - 1px
        media: audioElementRef.current,
        normalize: true,
      })

      const handleFinish = () => {
        setIsPlaying(false)
        Sentry.addBreadcrumb({
          category: 'audio',
          message: 'Audio playback finished',
          level: 'info',
        })
      }

      const handleError = (error: Error) => {
        setShowFakeElement(true)
        Sentry.captureException(error, {
          tags: { url },
          extra: {
            audioElement: {
              readyState: audioElementRef.current?.readyState,
              error: audioElementRef.current?.error?.message,
              networkState: audioElementRef.current?.networkState,
            },
            browserInfo: {
              userAgent: navigator.userAgent,
              vendor: navigator.vendor,
            },
          },
        })
      }

      const handleReady = () => {
        Sentry.addBreadcrumb({
          category: 'audio',
          message: 'WaveSurfer ready',
          level: 'info',
          data: {
            duration: wavesurferRef.current?.getDuration(),
            peaks: wavesurferRef.current?.exportPeaks(),
          },
        })
      }

      wavesurferRef.current.on('finish', handleFinish)
      wavesurferRef.current.on('error', handleError)
      wavesurferRef.current.on('ready', handleReady)

      return () => {
        wavesurferRef.current?.un('finish', handleFinish)
        wavesurferRef.current?.un('error', handleError)
        wavesurferRef.current?.un('ready', handleReady)
        wavesurferRef.current?.destroy()
      }
    }
    return () => {}
  }, [url])

  const onPlayPause = useCallback(() => {
    if (wavesurferRef.current) {
      wavesurferRef.current.playPause()
      setIsPlaying(!isPlaying)
    }
  }, [wavesurferRef, isPlaying])

  return (
    <div className="bg-secondary flex grow flex-row items-center gap-1 rounded-md shadow-sm">
      <Button
        size="icon"
        variant="secondary"
        type="button"
        onClick={onPlayPause}
      >
        {isPlaying ? <Pause className="size-4" /> : <Play className="size-4" />}
      </Button>
      <div className="flex h-10 grow flex-col overflow-hidden">
        {showFakeElement ? <FakeWaveform /> : <div ref={waveformRef} />}
        <audio src={url} ref={audioElementRef} className="hidden" />
      </div>
      {buttons}
    </div>
  )
}

const FakeWaveform = memo(() => {
  const bars = 50

  return (
    <div className="flex h-full w-full items-center gap-0.5 px-1">
      {[...Array(bars)].map((_, i) => {
        const height = 10 + Math.random() * 90
        return (
          <div
            key={i}
            className="bg-secondary-foreground w-1 grow rounded-sm"
            style={{ height: `${height}%` }}
          />
        )
      })}
    </div>
  )
})
