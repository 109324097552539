import * as React from 'react'
import { useImperativeHandle } from 'react'
import { cn } from 'shared/utils/web/cn'
import { defaultInputClassName } from './Input'

interface UseAutosizeTextAreaProps {
  textAreaRef: React.MutableRefObject<HTMLTextAreaElement | null>
  minHeight?: number
  maxHeight?: number
  triggerAutoSize: string
}

const useAutosizeTextArea = ({
  textAreaRef,
  triggerAutoSize,
  maxHeight = Number.MAX_SAFE_INTEGER,
  minHeight = 0,
}: UseAutosizeTextAreaProps) => {
  const isInitialized = React.useRef(false)

  React.useEffect(() => {
    // We need to reset the height momentarily to get the correct scrollHeight for the textarea
    const textAreaElement = textAreaRef.current
    if (textAreaElement) {
      if (!isInitialized.current) {
        textAreaElement.style.minHeight = `${minHeight}px`
        if (maxHeight > minHeight) {
          textAreaElement.style.maxHeight = `${maxHeight}px`
        }
        isInitialized.current = true
      }
      textAreaElement.style.height = `${minHeight}px`
      const scrollHeight = textAreaElement.scrollHeight
      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will product an incorrect value.
      if (scrollHeight > maxHeight) {
        textAreaElement.style.height = `${maxHeight}px`
      } else {
        textAreaElement.style.height = `${scrollHeight}px`
      }
    }
  }, [maxHeight, minHeight, textAreaRef, triggerAutoSize])
}

export type AutosizeTextAreaRef = {
  textArea: HTMLTextAreaElement
  maxHeight: number
  minHeight: number
}

type AutosizeTextAreaProps = {
  maxHeight?: number
  minHeight?: number
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>

export const AutosizeTextarea = React.forwardRef<
  AutosizeTextAreaRef,
  AutosizeTextAreaProps
>(
  (
    {
      maxHeight = 200,
      minHeight = 40, // h-10
      className,
      onChange,
      value,
      ...props
    }: AutosizeTextAreaProps,
    ref: React.Ref<AutosizeTextAreaRef>,
  ) => {
    const textAreaRef = React.useRef<HTMLTextAreaElement | null>(null)
    const [triggerAutoSize, setTriggerAutoSize] = React.useState('')

    useAutosizeTextArea({
      textAreaRef,
      triggerAutoSize,
      maxHeight,
      minHeight,
    })

    useImperativeHandle(ref, () => ({
      textArea: textAreaRef.current as HTMLTextAreaElement,
      focus: () => textAreaRef?.current?.focus(),
      maxHeight,
      minHeight,
    }))

    React.useEffect(() => {
      setTriggerAutoSize(value as string)
    }, [props?.defaultValue, value])

    return (
      <textarea
        value={value}
        onChange={(e) => {
          setTriggerAutoSize(e.target.value)
          onChange?.(e)
        }}
        className={cn(defaultInputClassName, className)}
        ref={textAreaRef}
        {...props}
      />
    )
  },
)
AutosizeTextarea.displayName = 'AutosizeTextarea'

export { AutosizeTextarea as Textarea }
