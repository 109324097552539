import { AlertType } from 'shared/types/alert'
import { TimeRange } from 'shared/types/timeRange'
import { FacilityId, FacilityName, UserId } from 'shared/types/utils'

export interface Device extends FacilityDevice {
  facilityId: FacilityId
  facilityName: FacilityName
  monitoringTimeRange: TimeRange
}

interface SnoozeInterval {
  start: number
  end: number
}

export type SnoozeHistory = Partial<
  Record<AlertType, Record<FirebaseKey, SnoozeInterval>>
>

export type FirebaseASUser = {
  email: string
  role: 'AS'
  facilityId: string
  zoneId?: string
  zoneLocked?: boolean
  notificationDisabled?: boolean
}

type FirebaseAdminUser = {
  email: string
  role: 'admin'
  phoneNumber?: string
  zoneId: undefined // Hacky for typed methods
}

export type FirebaseUser = FirebaseASUser | FirebaseAdminUser

export type CallStatus =
  | 'queued'
  | 'initiated'
  | 'ringing'
  | 'in-progress'
  | 'completed'
  | 'busy'
  | 'failed'
  | 'no-answer'
  | 'canceled'

export type CallStatusHistory = {
  duration: string // although it represents a number
  status: CallStatus[]
}

export type FirebaseCall = {
  id: number
  callerId: string
  callerEmail: string
  calleeId?: string
  calleeEmail?: string
}

export const automaticUid = 'automatic'

export type RawAlert = Omit<Alert, 'soundURL'> & {
  sound: string /* wav encoded sound */
}

interface BubbleBase {
  timestamp: number
  userId: UserId
  image?: { url: string; path: string }
}

interface TextBubble extends BubbleBase {
  text: string
}

interface AudioBubble extends BubbleBase {
  sound: { url: string; path: string }
}

export type Bubble = TextBubble | AudioBubble

export type BubbleUpdate = {
  image?: { url: string; path: string }
  sound?: { url: string; path: string }
}

export function isTextBubble(bubble: Bubble): bubble is TextBubble {
  return (bubble as TextBubble).text !== undefined
}

export function isAudioBubble(bubble: Bubble): bubble is AudioBubble {
  return (bubble as AudioBubble).sound !== undefined
}

// A conversation is no more than a collection of bubbles
// It is referenced by two users in their respective 'discussions'
// or by several users for 'channel' conversations
export type ConversationId = FirebaseKey | ChannelId
// Might become undefined when old bubbles are purged
export type Conversation = Record<FirebaseKey, Bubble>
export type Conversations = Partial<Record<ConversationId, Conversation>>

interface Discussion {
  conversationId: ConversationId
  unreadCount: number
}

// A ChannelId is used as a key in the 'discussions' data structure
// It is also the shared, associated conversationId in that case
export type ChannelId = string

// Double entry: userA / userB / discussion and userB / userA / discussion
// will share the same conversationId. unreadCount is per user.
export type UserDiscussions = Partial<Record<UserId | ChannelId, Discussion>>
export type Discussions = Partial<Record<UserId, UserDiscussions>>

export type Emergencies = Record<FirebaseKey, Emergency>

export interface Emergency {
  source: string
  date: string // ISO date
  sound: string
  handledAt?: string // ISO date
  handledBy?: UserId
  endAt?: string // ISO date
}

export type VideoSource = 'menu' | 'popup'
