import { z } from 'zod'
import { TimeZone, TimeZones } from '../utils/timeZones'
import { FacilityName, FirebaseKey, Room, Serial } from './utils'

export const ROLE_DURATION = 12 /* hours */ * 60 // minutes, minimum 15, maximum 12 hours

export interface BaseAuditAlert {
  id: FirebaseKey
  serial: Serial
  time: number
  timeZone: TimeZone
  room: Room
  type: string // plain, localized, displayed alert type
}

export interface AuditAlert extends BaseAuditAlert {
  // Added for MQTT logging purposes only
  facility: FacilityName
}

export const AuditAlertSchema = z.object({
  id: z.string(),
  serial: z.string(),
  // Received as a string, that should be coerced
  time: z.coerce.number().positive(),
  timeZone: z.enum(TimeZones),
  room: z.string(),
  type: z.string(),
  facility: z.string(),
}) satisfies z.ZodType<AuditAlert>

export interface AddAlertResponse {
  webSiteUrl: string
  expiration: number
}
