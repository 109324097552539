import { t, Trans } from '@lingui/macro'
import { ADMIN_USER_ID, channelNames, osoChannelId } from 'common/channels'
import { ConversationId } from 'common/types'
import { ArrowLeft } from 'lucide-react'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Centered } from 'shared/components/Centered'
import { FacilityId } from 'shared/types/utils'
import { cn } from 'shared/utils/web/cn'
import { Bubble } from '../../components/conversation/Bubble'
import { Button } from '../../components/ui/Button'
import { useUser } from '../../components/UserProvider'
import { update } from '../../firebaseMethods'
import { useFirebase } from '../../hooks/useFirebase'
import { ConversationContext } from './Communication'
import { Composer } from './Composer'
import { contactsContext } from './contactsProvider'

export type LastReadTS = number | undefined

interface Props {
  conversationContext: ConversationContext
  facilityId: FacilityId
  conversationId: ConversationId | undefined
  lastReadTS?: LastReadTS
  updateLastReadTS?: (ts: number) => void
  onClose?: () => void
}

export const Conversation: React.FC<Props> = ({
  conversationContext,
  facilityId,
  conversationId,
  lastReadTS,
  updateLastReadTS,
  onClose,
}) => {
  const { uid, role } = useUser()
  const { contacts } = useContext(contactsContext)

  const {
    data: conversation,
    loading,
    error,
  } = useFirebase(
    `conversations/${facilityId}/${conversationId ?? '_not_defined_'}`,
  )

  const [firstNewBubbleKey, setFirstNewBubbleKey] = useState<FirebaseKey>()
  const hasUpdatedLastRead = useRef(false)

  const listRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    listRef?.current?.scrollTo(0, listRef?.current?.scrollHeight)
  }, [conversation])

  useEffect(() => {
    if (conversation) {
      const id =
        conversationContext.channelId === undefined
          ? conversationContext.userId
          : conversationContext.channelId
      update(`discussions/${facilityId}/${uid}/${id}`, { unreadCount: 0 })
    }
  }, [facilityId, uid, conversationContext, conversation])

  useEffect(() => {
    if (!hasUpdatedLastRead.current && conversation) {
      if (lastReadTS !== undefined) {
        const firstNewBubbleEntry = Object.entries(conversation ?? {}).find(
          ([_key, bubble]) => bubble.timestamp > lastReadTS,
        )
        if (firstNewBubbleEntry !== undefined)
          setFirstNewBubbleKey(firstNewBubbleEntry[0])
      }

      updateLastReadTS && updateLastReadTS(Date.now())
      hasUpdatedLastRead.current = true
    }
  }, [conversation, lastReadTS, updateLastReadTS])

  if (loading)
    return (
      <ConversationWrapper onClose={onClose} title={t`Chargement...`}>
        <Centered>
          <Trans>Chargement...</Trans>
        </Centered>
      </ConversationWrapper>
    )
  if (error)
    return (
      <ConversationWrapper onClose={onClose} title={t`Erreur`}>
        <Centered>
          <Trans>Erreur</Trans>
        </Centered>
      </ConversationWrapper>
    )

  return (
    <ConversationWrapper
      onClose={onClose}
      title={
        conversationContext.channelId === undefined
          ? contacts[conversationContext.userId].name
          : conversationContext.channelName
      }
    >
      <div className="flex-1 overflow-y-auto" ref={listRef}>
        {conversation ? (
          Object.entries(conversation).map(([key, bubble]) => {
            const isUserBubble =
              bubble.userId === uid ||
              (bubble.userId === ADMIN_USER_ID && role === 'admin')

            return (
              <React.Fragment key={key}>
                {key === firstNewBubbleKey && (
                  <div className="border-t-background-notification h-1 w-full border-t-2 border-dotted py-2" />
                )}
                <div
                  className={cn(
                    'mb-3 flex flex-row ',
                    isUserBubble ? 'justify-end' : 'justify-start',
                  )}
                >
                  <Bubble
                    bubble={bubble}
                    zone={contacts[bubble.userId]?.zone}
                    author={
                      conversationContext.channelId === undefined
                        ? undefined
                        : // Assume messages from non-contacts must be posted by a channel admin
                          contacts[bubble.userId]?.name ??
                          channelNames[osoChannelId]
                    }
                  />
                </div>
              </React.Fragment>
            )
          })
        ) : (
          <Centered>
            <Trans>Pas de conversation</Trans>
          </Centered>
        )}
      </div>

      <Composer
        conversationId={conversationId}
        conversationContext={conversationContext}
        facilityId={facilityId}
      />
    </ConversationWrapper>
  )
}

type WrapperProps = {
  title: string
  onClose: (() => void) | undefined
}

const ConversationWrapper: React.FC<React.PropsWithChildren<WrapperProps>> = ({
  children,
  onClose,
  title,
}) => {
  return (
    <>
      <div className="flex flex-row items-center gap-2">
        {onClose && (
          <Button size="icon" onClick={onClose} variant="secondary">
            <ArrowLeft className="size-6" />
          </Button>
        )}
        <div className="flex-1 text-2xl font-bold">{title}</div>
      </div>
      {children}
    </>
  )
}
