import { Trans } from '@lingui/macro'
import { DialogDescription } from '@radix-ui/react-dialog'
import { useState } from 'react'
import { Button } from '../../components/ui/Button'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../../components/ui/Dialog'
import Arrow from '../../icons/arrow.svg?react'

export const InfoCommunicationDialog = () => {
  const [open, setOpen] = useState<boolean>(false)

  const handleClose = () => setOpen(false)

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <div className="flex flex-row space-x-2 align-baseline">
          <span>●</span>
          <span className="flex-1 cursor-pointer underline">
            <Trans>Messagerie</Trans>
          </span>
        </div>
      </DialogTrigger>
      <DialogContent className="bg-background-dialog text-dialog max-w-[90vw]">
        <DialogHeader>
          <DialogTitle
            className="flex cursor-pointer flex-row items-center space-x-2 text-lg"
            onClick={handleClose}
          >
            <Arrow className="w-4" fill="currentColor" />
            <span>
              <Trans>Accès messagerie</Trans>
            </span>
          </DialogTitle>
        </DialogHeader>
        <DialogDescription>
          <Trans>
            Pour accéder à la messagerie OSO, appuyez sur le bouton{' '}
            <b>Répertoire</b> depuis l'écran d'accueil, puis choississez :
          </Trans>
        </DialogDescription>
        <div>
          <b>
            <span>●</span> <Trans>Service Clients OSO</Trans>
          </b>{' '}
          <Trans>
            pour envoyer un message directement à nos équipes du service client.
          </Trans>
          <br />
          <b>
            <span>●</span> <Trans>Messagerie interne</Trans>
          </b>{' '}
          <Trans>
            est nouveau, et vous permet de discuter entre tous les téléphones de
            l'établissement.
          </Trans>
        </div>
        <DialogFooter>
          <Button onClick={handleClose} variant="outline" size="full">
            <Trans>Fermer</Trans>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
