import { useCallback, useEffect, useState } from 'react'
import { FacilityId } from 'shared/types/utils'
import { LastReadTS } from '../modules/Live/Conversation'

export type FacilityLastReadTS = Record<FacilityId, LastReadTS>

const LAST_READ_PREFIX = 'lastReadTS_'

export const useLastReadTS = (facilities: Record<string, Facility>) => {
  const [lastReadMap, setLastReadMap] = useState<FacilityLastReadTS>({})

  useEffect(() => {
    const initialLastRead = Object.keys(facilities).reduce<FacilityLastReadTS>(
      (acc, facilityId) => {
        const lastRead = localStorage.getItem(
          `${LAST_READ_PREFIX}${facilityId}`,
        )
        acc[facilityId] = lastRead ? parseInt(lastRead, 10) : 0
        return acc
      },
      {},
    )
    setLastReadMap(initialLastRead)
  }, [facilities])

  const updateLastRead = useCallback(
    (facilityIds: FacilityId[], timestamp: number) => {
      facilityIds.forEach((facilityId) => {
        localStorage.setItem(
          `${LAST_READ_PREFIX}${facilityId}`,
          timestamp.toString(),
        )
      })

      setLastReadMap((prev) => {
        const updatedMap = { ...prev }
        facilityIds.forEach((facilityId) => {
          updatedMap[facilityId] = timestamp
        })
        return updatedMap
      })
    },
    [],
  )

  return { lastReadTS: lastReadMap, updateLastRead }
}
